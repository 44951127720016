import React from 'react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import styled from 'styled-components';

export const Error404Container = styled.div`
  max-width: 1200px;
  min-height: 50vh;
  width: 60%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    min-height: 70vh;
  }
`;

export const Error404Title = styled.span`
  font-size: 170px;
  font-weight: 700;
  background-image: linear-gradient(90deg, #737cbc, #31acbf);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  line-height: 1;

  @media only screen and (max-width: 768px) {
    font-size: 100px;
  }
`;

export const Error404Caption = styled.span`
  color: #1e242f;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0;
`;

export const Error404CaptionWrapper = styled.div`
  margin-top: 1.5rem;
  @media only screen and (max-width: 768px) {
    margin-top: 0.5rem;
  }
`;

export const Error404Button = styled.div`
  border: 1.5px solid #31acbf;
  background: #31acbf;
  font-weight: 700;
  font-size: 13px;
  line-height: 1.5rem;
  color: #fff;
  width: 100%;
  transition: all 0.5s ease-in-out;
  min-width: 2.375rem;
  max-width: 250px;
  padding: 0.375rem 0.75rem;
  border-radius: 3px;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 1rem;

  &:hover {
    background: #fff;
    color: #31acbf;
  }

  @media only screen and (max-width: 768px) {
    width: unset;
  }
`;
/**
 * ===========================
 * MAIN
 * ===========================
 */
const Error404: React.FC = () => {
  const { t } = useTranslation('common');
  return (
    <Error404Container>
      <div
        style={{
          top: '20%',
          width: '100%',
        }}
      >
        <Error404Title>404</Error404Title>
        <Error404CaptionWrapper>
          <Error404Caption>{t('404caption')}</Error404Caption>
        </Error404CaptionWrapper>
        <Link href="/">
          <Error404Button>{t('backHome')}</Error404Button>
        </Link>
      </div>
    </Error404Container>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default Error404;
